import { HttpClient } from "@angular/common/http";
import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { lastValueFrom, Subscription } from "rxjs";

import { LayoutService, ParticipantAbstractModel, ParticipantService, TokenModel } from "openvidu-angular";
import { Session, SignalOptions } from "openvidu-browser";
import { RestService } from "src/app/services/rest.service";
import { LayoutAlignment, LayoutClass, OpenViduLayout } from "openvidu-angular/lib/models/layout.model";

@Component({
    selector: "mcu-root",
    templateUrl: './mcu.component.html',
    styleUrls: ['./mcu.component.scss'],
})


export class MCUComponent implements OnInit, OnDestroy, AfterViewInit {
    sessionId = '';
    type = 'STREAM';
    tokens!: TokenModel;
    session: Session;
    loading: boolean = true;
    initLayout: boolean = false;

    remoteParticipants!: ParticipantAbstractModel[];
    remoteParticipantsSubs!: Subscription;

    constructor(
        protected layoutService: LayoutService,
        private participantService: ParticipantService,
        private restService: RestService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    async ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.sessionId = params.roomName;
        });
        this.route.queryParams.subscribe((params: Params) => {
            this.type = params.type;
        });
        try {
            await this.initializeTokens();
        } finally {
            this.loading = false;
        }
    }
    ngAfterViewInit() {
        document.body.addEventListener("click", () => {
            let videos = document.querySelectorAll("video");
            videos.forEach(function (video) {
                video.play();
            })
        });
    }

    canShowVideo(stream) {
        // return true;
        let aConnection = [...this.session.remoteConnections.values()].filter((item) => item.connectionId == stream.connectionId);
        if (aConnection.length == 0) {
            return true;
        }
        let serverData = aConnection[0].data?.split("%/%");
        let meta = serverData[0];
        let obj = JSON.parse(meta);
        if (obj.participantId && obj.participantId == 'STREAM') {
            return true;
        }
        return false;
    }

    onSessionCreated(session: Session) {
        let self = this;
        this.session = session;
        this.remoteParticipantsSubs = this.participantService.remoteParticipantsObs.subscribe((participants) => {
            this.remoteParticipants = participants;
        });
        this.participantService.getLocalParticipant().id = this.type;
        this.participantService.setMyNickname(this.type);
        this.session.on(`sessionDisconnected`, async (event: any) => {
            window.close();
        });
    }

    ngOnDestroy() {
        this.remoteParticipantsSubs.unsubscribe();
    }

    private async initializeTokens(): Promise<void> {
        try {
            let nickname: string = '';
            const response = await this.restService.getTokens({
                sessionId: this.sessionId,
                role: "SUBSCRIBER",
                isScreen: false,
            });
            this.tokens = {
                webcam: response.cameraToken,
            };
        } catch (ex) {
            setTimeout(() => {
                this.initializeTokens();
            }, 5000);
        }
    }
}