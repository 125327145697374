<ov-videoconference *ngIf="!loading" [tokens]="tokens" [streamSettingsButton]="false"
    (onSessionCreated)="onSessionCreated($event)" [prejoin]="false">
    <ng-container *ovLayout>
        <div class="layout" #layout>
            <ng-container *ngFor="let stream of remoteParticipants | streams">
                <div class="item" *ngIf="canShowVideo(stream)" class="OT_root OT_publisher OV_big"
                    id="remote-participant">
                    <ov-stream [stream]="stream"></ov-stream>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ovToolbar>
    </ng-container>
</ov-videoconference>