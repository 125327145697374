import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.services';
import { RestService } from 'src/app/services/rest.service';
import { uniqueNamesGenerator, NumberDictionary, Config } from 'unique-names-generator';
import packageInfo from '../../../../package.json';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
	sessionForm: UntypedFormGroup = new UntypedFormGroup({
		sessionName: new UntypedFormControl('', [Validators.minLength(6), Validators.required]),
		sessionPassword: new UntypedFormControl('', []),
		username: new UntypedFormControl('', []),
		password: new UntypedFormControl('', [])
	});
	errorMessage: string;
	username: string;
	isUserLogged: boolean = false;
	loading: boolean = true;
	roomInfo: object;
	private queryParamSubscription: Subscription;
	private loginSubscription: Subscription;

	constructor(
		private router: Router,
		public formBuilder: UntypedFormBuilder,
		private authService: AuthService,
		private restService: RestService,
		private route: ActivatedRoute
	) { }

	async ngOnInit() {
		this.queryParamSubscription = this.route.queryParams.subscribe(async (params) => {
			if (!!params?.sessionId) {
				const sessionId = params.sessionId.replace(/[^\w-]/g, '');
				this.sessionForm.get('sessionName').setValue(sessionId);
				await this.getRoomInfo(sessionId);
			}
			if (!!params?.error) {
				this.errorMessage = params.error;
			}
		});

		try {
			if (this.roomInfo && this.roomInfo['is_privateaccess'] == 1) {
				this.subscribeToLogin();
				this.sessionForm.get('username').setValidators(Validators.required);
				this.sessionForm.get('username').setValue(this.authService.getUsername());
				this.sessionForm.get('password').setValidators(Validators.required);
				this.sessionForm.get('password').setValue(this.authService.getPassword());
			}

			this.username = this.authService.getUsername();
		} catch (error) {
			this.errorMessage = error.message;
		} finally {
			this.loading = false;
		}
	}

	ngOnDestroy(): void {
		if (this.queryParamSubscription) this.queryParamSubscription.unsubscribe();
		if (this.loginSubscription) this.loginSubscription.unsubscribe();
	}
	async getRoomInfo(sessionId) {
		let roomInfo = await this.restService.getRoomInfo(sessionId);
		if (roomInfo["status"] == "ko") {
			this.errorMessage = roomInfo["message"];
			return;
		}
		this.roomInfo = roomInfo["room"];
		if (this.roomInfo && this.roomInfo['is_privateaccess'] == 1) {
			this.subscribeToLogin();
		}
	}
	generateSessionName(event) {
		event.preventDefault();
		this.sessionForm.get('sessionName').setValue(this.getRandomName());
	}

	keyDown(event) {
		this.errorMessage = null;
		if (event.keyCode === 13) {
			event.preventDefault();
			this.joinSession();
		}
	}

	async joinSession() {
		let roomId = this.sessionForm.get('sessionName').value;
		if (!this.roomInfo || (this.roomInfo && this.roomInfo["code"] != roomId)) {
			await this.getRoomInfo(roomId);
		}
		if (!this.sessionForm.valid) {
			return;
		}
		//
		if (this.roomInfo && this.roomInfo["is_privateaccess"] == 1) {
			this.username = this.sessionForm.get('username').value;
			const password = this.sessionForm.get('password').value;

			var res = await this.restService.login(this.username, password);
			if (res["status"] == "ko") {
				this.errorMessage = res["message"];
				return;
			}
			await this.authService.setToken(res["token"]);
			await this.authService.setIsLogged(true);
		}
		if (this.roomInfo && this.roomInfo["is_password"] == 1) {
			let password = this.sessionForm.get("sessionPassword").value;
			let roomInfo = await this.restService.canJoinRoom(roomId, password);

			if (roomInfo["status"] == "ko" || roomInfo["is_ok"] == 0) {
				this.errorMessage = roomInfo["message"];
				return;
			}
			await this.authService.setIsPassworded(true);
		}
		this.navigateToVideoconference();
	}

	logout() {
		this.authService.logout();
	}
	private subscribeToLogin() {
		this.loginSubscription = this.authService.isLoggedObs.subscribe((isLogged) => {
			this.isUserLogged = isLogged;
		});
	}


	private navigateToVideoconference() {
		const roomName = this.sessionForm.get('sessionName').value.replace(/ /g, '-');
		this.sessionForm.get('sessionName').setValue(roomName);
		this.router.navigate(['/', roomName]);
	}

	private getRandomName(): string {
		const numberDictionary = NumberDictionary.generate({ min: 100000, max: 999999 });
		const configName: Config = {
			dictionaries: [numberDictionary],
			separator: '-',
			style: 'lowerCase'
		};
		return uniqueNamesGenerator(configName).replace(/[^\w-]/g, '');
	}
}
