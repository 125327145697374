<div class="section1">
	<mat-toolbar id="header">
		<div *ngIf="isUserLogged" id="logout-content">
			<span>Hi {{ username }}, bạn có muốn đăng xuất?</span>
			<button matTooltip="Logout" mat-icon-button id="logout-btn" (click)="logout()">
				<mat-icon>logout</mat-icon>
			</button>
		</div>
	</mat-toolbar>
	<div class="openvidu-slogan-container">
		<a href="hhttps://moc.gov.vn/" target="_blank">
			<img id="form-img" src="assets/images/logo.png" width="150" />
		</a>
		<h4 id="slogan-text">Hệ thống họp trực tuyến</h4>
	</div>
	<div class="form-container">
		<div class="grid">
			<form [formGroup]="sessionForm" novalidate (ngSubmit)="joinSession()" id="form-session" class="form login">
				<div class="roomError" id="requiredNameError"
					*ngIf="sessionForm.get('sessionName').hasError('required')">
					Bắt buộc nhập số phòng!
				</div>
				<div class="roomError" id="shortNameError" *ngIf="sessionForm.get('sessionName').hasError('minlength')">
					Số phòng quá ngắn!
				</div>
				<div class="roomError" id="shortNameError" *ngIf="errorMessage">
					{{errorMessage}}
				</div>
				<span class="form-field-header">Số phòng họp</span>
				<div class="form-field">
					<label for="session-name-input" [ngClass]="{ error: sessionForm.get('sessionName').invalid }">
						<mat-icon matTooltip="Số phòng họp">video_camera_front</mat-icon>
						<span class="hidden">Số phòng họp</span>
					</label>
					<input formControlName="sessionName" autocomplete="off" type="text" name="sessionName"
						class="form-input" placeholder="Số phòng họp" (keydown)="keyDown($event)" />
				</div>
				<span class="form-field-header" *ngIf="roomInfo && roomInfo['is_password'] == 1">Mật khẩu phòng
					họp</span>
				<div class="form-field" *ngIf="roomInfo && roomInfo['is_password'] == 1">
					<label for="session-name-input" [ngClass]="{ error: sessionForm.get('sessionName').invalid }">
						<mat-icon matTooltip="Mật khẩu phòng họp">password</mat-icon>
						<span class="hidden">Mật khẩu phòng họp</span>
					</label>
					<input formControlName="sessionPassword" autocomplete="off" type="password" name="sessionPassword"
						class="form-input" placeholder="Mật khẩu phòng họp" />
				</div>

				<span class="form-field-header"
					*ngIf="!isUserLogged && roomInfo && roomInfo['is_privateaccess'] == 1">Tên đăng nhập</span>
				<div *ngIf="!isUserLogged && roomInfo && roomInfo['is_privateaccess'] == 1" class="form-field"
					id="login-username">
					<label for="login-username" [ngClass]="{ error: loginError }">
						<mat-icon matTooltip="Username">person</mat-icon>
						<span class="hidden">Tên đăng nhập</span>
					</label>
					<input formControlName="username" autocomplete="off" type="text" name="username" class="form-input"
						placeholder="Tên đăng nhập" />
				</div>

				<span class="form-field-header"
					*ngIf="!isUserLogged && roomInfo && roomInfo['is_privateaccess'] == 1">Mật khẩu đăng nhập</span>
				<div *ngIf="!isUserLogged && roomInfo && roomInfo['is_privateaccess'] == 1" class="form-field"
					id="login-password">
					<label for="login-password" [ngClass]="{ error: loginError }">
						<mat-icon matTooltip="Password">lock</mat-icon>
						<span class="hidden">Mật khẩu đăng nhập</span>
					</label>
					<input formControlName="password" type="password" name="password" class="form-input"
						placeholder="Mật khẩu" />
				</div>


				<div class="form-field">
					<button mat-button id="join-btn" type="submit" [disabled]="sessionForm.invalid">
						Tham dự họp
					</button>
				</div>
			</form>
		</div>
	</div>

	<mat-toolbar class="footer">
		<span>
			Tạo bởi
			<a rel="noopener noreferrer" target="_blank" href="tefli.vn">
				tefli.vn
			</a>
		</span>
	</mat-toolbar>
</div>