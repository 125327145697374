import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth.services';
import { RestService } from '../rest.service';

@Injectable({
	providedIn: 'root'
})
export class NavigationGuardService implements CanActivate {
	constructor(private router: Router, private restService: RestService, private authService: AuthService) { }

	// Check if user can navigate to the route
	async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		let roomInfo = await this.restService.getRoomInfo(state.url.replace("/", ""));
		if (!roomInfo || roomInfo["status"] == "ko") {
			return false;
		}
		if (roomInfo["room"]["is_start"] == 0) {
			this.router.navigate(['/'], { queryParams: { sessionId: state.url, error: "Phòng họp chưa bắt đầu" } });
			return false;
		}
		if (roomInfo["room"]["is_lock"] == 1) {
			this.router.navigate(['/'], { queryParams: { sessionId: state.url, error: "Phòng họp đã bị khóa" } });
			return false;
		}
		if (roomInfo["room"]["is_private"] == 1 && !this.authService.isLogged()) {
			this.router.navigate(['/'], { queryParams: { sessionId: state.url, error: "Phòng họp cần đăng nhập" } });
			return false;
		}
		if (roomInfo["room"]["is_password"] == 1 && !this.authService.isPassworded()) {
			this.router.navigate(['/'], { queryParams: { sessionId: state.url, error: "Phòng họp cần nhập mật khẩu" } });
			return false;
		}
		return true;
	}
}
