import { ParticipantAbstractModel } from 'openvidu-angular';

export class ParticipantAppModel extends ParticipantAbstractModel {

	type: String;
	hasHandRaised: boolean;

	toggleHandRaised() {
		this.hasHandRaised = !this.hasHandRaised;
	}

	getType() {

	}
}