import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallComponent } from './components/call/call.component';
import { HomeComponent } from './components/home/home.component';
import { RecordingComponent } from './components/recording/recording.component';
import { MCUComponent } from './components/mcu/mcu.component';
import { NavigationGuardService } from './services/guards/navigation-guard.service';

const routes: Routes = [
	{ path: '', component: HomeComponent },
	{ path: ':roomName', component: CallComponent, canActivate: [NavigationGuardService] },
	{ path: ':roomName/recording', component: RecordingComponent },
	{ path: ':roomName/mcu', component: MCUComponent }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { useHash: true }),
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
