<ov-videoconference *ngIf="!loading && !serverError" [tokens]="tokens"
	[recordingActivityRecordingsList]="recordingList" [recordingActivityRecordingError]="recordingError"
	(onToolbarLeaveButtonClicked)="onLeaveButtonClicked()" (onToolbarStartRecordingClicked)="onStartRecordingClicked()"
	(onActivitiesPanelStartRecordingClicked)="onStartRecordingClicked()"
	(onToolbarStopRecordingClicked)="onStopRecordingClicked()"
	(onActivitiesPanelStopRecordingClicked)="onStopRecordingClicked()"
	(onActivitiesPanelDeleteRecordingClicked)="onDeleteRecordingClicked($event)" (onNodeCrashed)="onNodeCrashed()"
	(onSessionCreated)="onSessionCreated($event)" [activitiesPanelRecordingActivity]="recordingEnabled"
	[toolbarRecordingButton]="recordingEnabled" [toolbarBackgroundEffectsButton]="false" [prejoin]="true"
	[toolbarCaptionsButton]="false">

    <ng-container *ovLayout>
        <div class="layout" #layout>
            <div class="item OV_big" *ngIf="isHaveBig()">
            </div>
            <ng-container *ngFor="let stream of localParticipant | streams">
                <div data-type="local" class="item" *ngIf="canShowVideo(stream)" class="OT_root OT_publisher"
                id="remote-participant" [ngClass]="{ OV_big: canBig(stream) }">
                    <ov-stream [stream]="stream"></ov-stream>
                </div>
            </ng-container>
            <ng-container *ngFor="let stream of remoteParticipants | streams">
                <div data-type="remote" class="item" *ngIf="canShowVideo(stream)" class="OT_root OT_publisher"
                id="remote-participant" [ngClass]="{ OV_big: canBig(stream) }">
                    <ov-stream [stream]="stream"></ov-stream>
                </div>
            </ng-container>
        </div>
    </ng-container>

	<div *ovToolbarAdditionalButtons>
		<button toolbar-btn mat-icon-button (click)="handleLocalHand()" [class.active-btn]="hasHandRaised">
			<mat-icon matTooltip="Toggle hand">front_hand</mat-icon>
		</button>
	</div>

	<div *ovStream="let stream" style="height: 100%">
		<ov-stream [stream]="stream"></ov-stream>
		<button mat-icon-button @inOutHandAnimation id="hand-notification" *ngIf="stream.participant.hasHandRaised">
			<mat-icon>front_hand</mat-icon>
		</button>
	</div>

	<div *ovParticipantPanelItemElements="let participant">
		<button mat-icon-button *ngIf="participant.hasHandRaised">
			<mat-icon>front_hand</mat-icon>
		</button>
	</div>
</ov-videoconference>

<div *ngIf="!loading && serverError" class="error">
	<mat-icon>error</mat-icon>
	<span>
		{{ serverError }}
	</span>
</div>