import { HttpClient } from "@angular/common/http";
import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { lastValueFrom, Subscription } from "rxjs";

import { LayoutService, ParticipantAbstractModel, OpenViduService, ParticipantService, TokenModel } from "openvidu-angular";
import { Session, SignalOptions } from "openvidu-browser";
import { RestService } from "src/app/services/rest.service";
import { LayoutAlignment, LayoutClass, OpenViduLayout } from "openvidu-angular/lib/models/layout.model";

@Component({
    selector: "recording-root",
    templateUrl: './recording.component.html',
    styleUrls: ['./recording.component.scss'],
})


export class RecordingComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
    sessionId = '';
    type = 'VIEWER';
    layout = 'bestfit';
    roomInfo = null;
    @ViewChild('layout', { static: false, read: ViewContainerRef }) layoutContainer: ViewContainerRef;
    tokens!: TokenModel;
    session: Session;
    loading: boolean = true;
    initLayout: boolean = false;

    remoteParticipants!: ParticipantAbstractModel[];
    remoteParticipantsSubs!: Subscription;

    constructor(
        protected layoutService: LayoutService,
		private openviduService: OpenViduService,
        private participantService: ParticipantService,
        private restService: RestService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    async getRoomInfo(sessionId) {
        let roomInfo = await this.restService.getRoomInfo(sessionId);
        this.roomInfo = roomInfo;
        if (roomInfo["room"]) {
            this.layout = roomInfo["room"]["layout"];
        }
    }

    async ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.sessionId = params.roomName;
        });
        this.route.queryParams.subscribe((params: Params) => {
            this.type = params.type;
        });
        try {
            await this.initializeTokens();
            await this.getRoomInfo(this.sessionId);
        } finally {
            this.loading = false;
        }
    }
    ngAfterViewChecked() {
        if (this.layoutContainer != undefined && !this.initLayout) {
            this.initLayout = true;
            this.layoutService.initialize(this.layoutContainer.element.nativeElement);
            this.changeLayout();
        }

    }
    ngAfterViewInit() {
        document.body.addEventListener("click", () => {
            let videos = document.querySelectorAll("video");
            videos.forEach(function (video) {
                video.play();
            })
        });
    }
    changeLayout() {
        let defaultOptions = {
            maxRatio: 3 / 2,
            minRatio: 9 / 16,
            fixedRatio: false,
            bigClass: "OV_big",
            smallClass: "OV_small",
            ignoredClass: "OV_ignored",
            bigPercentage: 0.8,
            minBigPercentage: 0,
            bigFixedRatio: false,
            bigMaxRatio: 9 / 16,
            bigMinRatio: 9 / 16,
            bigFirst: true,
            animate: true,
            alignItems: "center",
            bigAlignItems: "center",
            smallAlignItems: "center",
            maxWidth: Infinity,
            maxHeight: Infinity,
            smallMaxWidth: Infinity,
            smallMaxHeight: Infinity,
            bigMaxWidth: Infinity,
            bigMaxHeight: Infinity,
            scaleLastRow: true,
            bigScaleLastRow: true
        };
        if (this.layoutService.getLayout() == undefined) {
            return;
        }

        if (this.layout == 'bestfit') {
            this.layoutService.getLayout().updateLayout(this.layoutContainer.element.nativeElement, defaultOptions);
        }
        if (this.layout == "left") {
            this.layoutService.getLayout().updateLayout(this.layoutContainer.element.nativeElement, { ...defaultOptions, bigFirst: false });
        }
        if (this.layout == "right") {
            this.layoutService.getLayout().updateLayout(this.layoutContainer.element.nativeElement, { ...defaultOptions, bigFirst: true });
        }

    }
    isHaveBig() {
        let isHaveShare = false;
        for (var i = 0; i < this.remoteParticipants.length; i++) {
            var aItem = this.remoteParticipants[i];
            var bigs = [...aItem.streams.values()].filter((x) => x.videoEnlarged);
            if (bigs.length > 0) {
                isHaveShare = true;
                break;
            }

        }
        return this.layout != 'bestfit' && !isHaveShare;
    }

    canBig(stream) {
        if(this.layout == 'bestfit'){
            return false;
        }
        return stream.videoEnlarged;
    }

    canShowVideo(stream) {
        // return true;
        let aConnection = [...this.session.remoteConnections.values()].filter((item) => item.connectionId == stream.connectionId);
        if (aConnection.length == 0) {
            return true;
        }
        let serverData = aConnection[0].data?.split("%/%");
        let meta = serverData[0];
        let obj = JSON.parse(meta);
        if (obj.participantId
            && (obj.participantId == 'RECORDER'
                || obj.participantId == 'VIEWER'
                || obj.participantId == 'STREAM'
                || obj.participantId == 'STT')
        ) {
            return false;
        }
        return true;
    }

    onSessionCreated(session: Session) {
        let self = this;
        this.session = session;
        this.remoteParticipantsSubs = this.participantService.remoteParticipantsObs.subscribe((participants) => {
            this.remoteParticipants = participants;
            this.changeLayout();
        });
        this.participantService.getLocalParticipant().id = this.type;
        this.participantService.setMyNickname(this.type);
        this.session.on(`signal:setlayout`, async (event: any) => {
            this.layout = event.data;
            self.changeLayout();
        });
        this.session.on(`signal:focuscamera`, async (event: any) => {
            if(self.layout == "bestfit"){
                self.layout = "left";
            }
            var connectionId = event.data;

			this.participantService.getLocalParticipant().streams.forEach((conn) => {
				if (conn.connectionId === connectionId) {
					conn.videoEnlarged = !conn.videoEnlarged;
				}
			});
			for (let i = 0; i < self.remoteParticipants.length; i++) {
				self.remoteParticipants[i].streams.forEach((conn) => {
					if (conn.connectionId === connectionId) {
						conn.videoEnlarged = !conn.videoEnlarged;
					}
				});
			}

			// if (self.openviduService.isMyOwnConnection(connectionId)) {
			// 	self.participantService.toggleMyVideoEnlarged(connectionId);
			// } else {
			// 	self.participantService.toggleRemoteVideoEnlarged(connectionId);
			// }
            // self.changeLayout();
        });
        this.session.on(`sessionDisconnected`, async (event: any) => {
            window.close();
        });
        this.session.on("streamCreated", (event) => {
            this.changeLayout();
        });
        this.session.on("streamDestroyed", (event) => {
            this.changeLayout();
        });
        this.session.on("connectionCreated", (event) => {
            this.changeLayout();
        });
        this.session.on("connectionDestroyed", (event) => {
            this.changeLayout();
        });
    }

    ngOnDestroy() {
        this.remoteParticipantsSubs.unsubscribe();
        this.layoutService.clear();
    }

    private async initializeTokens(): Promise<void> {
        try {
            let nickname: string = '';
            const response = await this.restService.getTokens({
                sessionId: this.sessionId,
                role: "SUBSCRIBER",
                isScreen: false,
            });
            this.tokens = {
                webcam: response.cameraToken,
            };
        } catch (ex) {
            setTimeout(() => {
                this.initializeTokens();
            }, 5000);
        }
    }
}