import { AfterViewChecked,Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LayoutService,  ParticipantService, OpenViduService, RecordingInfo, TokenModel, ParticipantAbstractModel } from 'openvidu-angular';

import { RestService } from '../../services/rest.service';
import { trigger, transition, style, animate } from "@angular/animations";
import { ParticipantAppModel } from "../../models/participant-app.model";


import { Session, SignalOptions } from "openvidu-browser";
import { Subscription } from 'rxjs';

enum SignalApp {
	HAND_TOGGLE = 'handToggle'
}

@Component({
	selector: 'app-call',
	templateUrl: './call.component.html',
	styleUrls: ['./call.component.scss'],
	animations: [
		trigger('inOutHandAnimation', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateY(-100%)' }),
				animate('300ms ease-in-out', style({ opacity: 1, transform: 'translateY(0)' }))
			]),
			transition(':leave', [
				style({ opacity: 1, transform: 'translateY(0)' }),
				animate('300ms ease-in-out', style({ opacity: 0, transform: 'translateY(-100%)' }))
			])
		])
	]
})
export class CallComponent implements OnInit, AfterViewChecked {
	sessionId = '';
    layout = 'bestfit';
    @ViewChild('layout', { static: false, read: ViewContainerRef }) layoutContainer: ViewContainerRef;
    initLayout: boolean = false;

	tokens: TokenModel;
	joinSessionClicked: boolean = false;
	closeClicked: boolean = false;
	isSessionAlive: boolean = false;
	recordingEnabled: boolean = true;
	recordingList: RecordingInfo[] = [];
	recordingError: any;
	serverError: string = '';
	loading: boolean = true;
	private isDebugSession: boolean = false;
	hasHandRaised: boolean = false;
	session: Session;

	localParticipant!: ParticipantAbstractModel;
	remoteParticipants!: ParticipantAbstractModel[];
	localParticipantSubs!: Subscription;
	remoteParticipantsSubs!: Subscription;

	constructor(
        protected layoutService: LayoutService,
		private restService: RestService,
		private openviduService: OpenViduService,
		private participantService: ParticipantService,
		private router: Router,
		private route: ActivatedRoute
	) { }

    ngAfterViewChecked() {
        if (this.layoutContainer != undefined && !this.initLayout) {
            this.initLayout = true;
            this.layoutService.initialize(this.layoutContainer.element.nativeElement);
            this.changeLayout();
        }

    }
	ngOnDestroy() {
		this.localParticipantSubs.unsubscribe();
		this.remoteParticipantsSubs.unsubscribe();
	}

	async ngOnInit() {
		this.route.params.subscribe((params: Params) => {
			this.sessionId = params.roomName;
		});

		// Just for debugging purposes
		const regex = /^UNSAFE_DEBUG_USE_CUSTOM_IDS_/gm;
		const match = regex.exec(this.sessionId);
		this.isDebugSession = match && match.length > 0;

		try {
			await this.initializeTokens();
		} catch (error) {
			console.error(error);
			this.serverError = error?.error?.message || error?.statusText;
		} finally {
			this.loading = false;
		}
	}

	onSessionCreated(session: Session) {
		let self = this;
		this.session = session;
		this.localParticipantSubs = this.participantService.localParticipantObs.subscribe((participants) => {
			this.localParticipant = participants;
		});
		this.remoteParticipantsSubs = this.participantService.remoteParticipantsObs.subscribe((participants) => {
			this.remoteParticipants = participants;
            this.changeLayout();
		});
		// Subscribe to hand toggling events from other participants
		this.session.on(`signal:${SignalApp.HAND_TOGGLE}`, (event: any) => {
			const connectionId = event.from.connectionId;
			const participant = <ParticipantAppModel>self.participantService.getRemoteParticipantByConnectionId(connectionId);
			if (participant) {
				participant.toggleHandRaised();
				self.participantService.updateRemoteParticipants();
			}
		});
		this.session.on(`signal:videoall`, async (event: any) => {
			await self.openviduService.publishVideo(true);
		});
		this.session.on(`signal:unvideoall`, async (event: any) => {
			await self.openviduService.publishVideo(false);
		});
		this.session.on(`signal:muteall`, async (event: any) => {
			await self.openviduService.publishAudio(false);
		});
		this.session.on(`signal:unmuteall`, async (event: any) => {
			await self.openviduService.publishAudio(true);
		});
		this.session.on(`signal:togglemic`, async (event: any) => {
			const publishAudio = !self.participantService.isMyAudioActive();
			await self.openviduService.publishAudio(publishAudio);
		});
		this.session.on(`signal:togglecamera`, async (event: any) => {
			const publishVideo = !self.participantService.isMyVideoActive();
			await self.openviduService.publishVideo(publishVideo);
		});
        this.session.on(`signal:focuscamera`, async (event: any) => {
            var connectionId = event.data;
            if(self.layout == "bestfit"){
                self.layout = "left";
            }
			//
			self.localParticipant.streams.forEach((conn) => {
				if (conn.connectionId === connectionId) {
					conn.videoEnlarged = !conn.videoEnlarged;
				}
			});
			for (let i = 0; i < self.remoteParticipants.length; i++) {
				self.remoteParticipants[i].streams.forEach((conn) => {
					if (conn.connectionId === connectionId) {
						conn.videoEnlarged = !conn.videoEnlarged;
					}
				});
			}

			// if (self.openviduService.isMyOwnConnection(connectionId)) {
			// 	self.participantService.toggleMyVideoEnlarged(connectionId);
			// } else {
			// 	self.participantService.toggleRemoteVideoEnlarged(connectionId);
			// }
            self.changeLayout();
        });
		this.session.on(`signal:unscreenshareall`, async (event: any) => {
			if (self.participantService.isMyScreenActive()) {
				self.openviduService.toggleScreenshare();
			}
		});
        this.session.on(`signal:setlayout`, async (event: any) => {
            this.layout = event.data;
            self.changeLayout();
        });
		this.session.on(`sessionDisconnected`, async (event: any) => {
			this.router.navigate(['/']);
		});
        this.session.on("streamCreated", (event) => {
            this.changeLayout();
        });
        this.session.on("streamDestroyed", (event) => {
            this.changeLayout();
        });
        this.session.on("connectionCreated", (event) => {
            this.changeLayout();
        });
        this.session.on("connectionDestroyed", (event) => {
            this.changeLayout();
        });

	}

	handleLocalHand() {
		// Get local participant with ParticipantService
		const participant = <ParticipantAppModel>this.participantService.getLocalParticipant();

		// Toggle the participant hand with the method we wil add in our ParticipantAppModel
		participant.toggleHandRaised();

		// Refresh the local participant object for others component and services
		this.participantService.updateLocalParticipant();

		// Send a signal with the new value to others participant using the openvidu-browser signal
		const remoteConnections = this.openviduService.getRemoteConnections();
		if (remoteConnections.length > 0) {
			//Sending hand toggle signal to others
			const signalOptions: SignalOptions = {
				type: SignalApp.HAND_TOGGLE,
				to: remoteConnections
			};
			this.session.signal(signalOptions);
		}
	}
    changeLayout() {
        let defaultOptions = {
            maxRatio: 3 / 2,
            minRatio: 9 / 16,
            fixedRatio: false,
            bigClass: "OV_big",
            smallClass: "OV_small",
            ignoredClass: "OV_ignored",
            bigPercentage: 0.8,
            minBigPercentage: 0,
            bigFixedRatio: false,
            bigMaxRatio: 9 / 16,
            bigMinRatio: 9 / 16,
            bigFirst: true,
            animate: true,
            alignItems: "center",
            bigAlignItems: "center",
            smallAlignItems: "center",
            maxWidth: Infinity,
            maxHeight: Infinity,
            smallMaxWidth: Infinity,
            smallMaxHeight: Infinity,
            bigMaxWidth: Infinity,
            bigMaxHeight: Infinity,
            scaleLastRow: true,
            bigScaleLastRow: true
        };
        if (this.layoutService.getLayout() == undefined) {
            return;
        }

        if (this.layout == 'bestfit') {
            this.layoutService.getLayout().updateLayout(this.layoutContainer.element.nativeElement, defaultOptions);
        }
        if (this.layout == "left") {
            this.layoutService.getLayout().updateLayout(this.layoutContainer.element.nativeElement, { ...defaultOptions, bigFirst: false });
        }
        if (this.layout == "right") {
            this.layoutService.getLayout().updateLayout(this.layoutContainer.element.nativeElement, { ...defaultOptions, bigFirst: true });
        }

    }
	async onNodeCrashed() {
		// Request the tokens again for reconnect to the session
		await this.initializeTokens();
	}
    isHaveBig() {
        if(this.layout == 'bestfit'){
            return false;
        }
        let isHaveShare = false;
		this.localParticipant.streams.forEach((conn) => {
			if (conn.videoEnlarged) {
				isHaveShare = true;
				return;
			}
		});

        for (var i = 0; i < this.remoteParticipants.length; i++) {
            var aItem = this.remoteParticipants[i];
            var bigs = [...aItem.streams.values()].filter((x) => x.videoEnlarged);
            if (bigs.length > 0) {
                isHaveShare = true;
                break;
            }

        }
		if(isHaveShare){
			return false;
		}
        return true;
    }

    canBig(stream) {
        if(this.layout == 'bestfit'){
            return false;
        }
        return stream.videoEnlarged;
    }


    canShowVideo(stream) {
        // return true;
        let aConnection = [...this.session.remoteConnections.values()].filter((item) => item.connectionId == stream.connectionId);
        if (aConnection.length == 0) {
            return true;
        }
        let serverData = aConnection[0].data?.split("%/%");
        let meta = serverData[0];
        let obj = JSON.parse(meta);
        if (obj.participantId
            && (obj.participantId == 'RECORDER'
                || obj.participantId == 'VIEWER'
                || obj.participantId == 'STREAM'
                || obj.participantId == 'STT')
        ) {
            return false;
        }
        return true;
    }

	onLeaveButtonClicked() {
		this.isSessionAlive = false;
		this.closeClicked = true;
		this.router.navigate([`/`]);
	}
	async onStartRecordingClicked() {
		try {
			await this.restService.startRecording(this.sessionId);
		} catch (error) {
			this.recordingError = error;
		}
	}

	async onStopRecordingClicked() {
		try {
			this.recordingList = await this.restService.stopRecording(this.sessionId);
		} catch (error) {
			this.recordingError = error;
		}
	}

	async onDeleteRecordingClicked(recordingId: string) {
		try {
			this.recordingList = await this.restService.deleteRecording(recordingId);
		} catch (error) {
			this.recordingError = error;
		}
	}

	private async initializeTokens(): Promise<void> {
		let nickname: string = '';
		if (this.isDebugSession) {
			console.warn('DEBUGGING SESSION');
			nickname = this.participantService.getLocalParticipant().getNickname();
		}
		const response = await this.restService.getTokens({
			sessionId: this.sessionId,
			nickname: nickname,
			role: "PUBLISHER",
			isScreen: true
		});

		this.recordingEnabled = response.recordingEnabled;
		this.recordingList = response.recordings;
		this.tokens = {
			webcam: response.cameraToken,
			screen: response.screenToken
		};
	}
}
