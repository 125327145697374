import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RestService } from './rest.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	isLoggedObs: Observable<boolean>;
	private AUTH_DATA_NAME = 'callAuthData';
	private username: string;
	private password: string;
	private logged: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private passworded: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(private restService: RestService) {
		this.isLoggedObs = this.logged.asObservable();
	}

	isLogged() {
		return this.logged.getValue();
	}

	isPassworded() {
		return this.passworded.getValue();
	}


	getUsername(): any {
		return this.username;
	}
	getPassword(): any {
		return this.password;
	}

	getCredentialsFromStorage() {
		return localStorage.getItem(this.AUTH_DATA_NAME);
	}

	async setToken(token) {
		localStorage.setItem(this.AUTH_DATA_NAME, token);
	}

	async setIsLogged(isAuthen) {
		this.logged.next(isAuthen);
	}

	async setIsPassworded(isAuthen) {
		this.passworded.next(isAuthen);
	}

	logout() {
		this.username = '';
		this.password = '';
		this.logged.next(false);
		this.clearAuthData();
	}

	private clearAuthData() {
		localStorage.removeItem(this.AUTH_DATA_NAME);
	}
}
